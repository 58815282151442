import { mdiDotsHorizontal, mdiDownload, mdiFileDocumentOutline, mdiFileDownloadOutline, mdiImageOutline, mdiLock, mdiPencil, mdiUpload } from '@mdi/js'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'
import { HiddenIframe } from 'sfportal_components_generic/HiddenIframe'
import { ListItemTaskAssetUtils } from 'sfportal_components_listitem_utils_listitemtaskasset/ListItemTaskAssetUtils'
import { claimTasks, getStoreTaskById, loadCorrectionPdf, setCurrentFile, setCurrentTask, setLock, updateTaskStateSingle, useProductDetailStore } from 'sfportal_stores/productDetailStore'
import { startMetadataWf, uploadAndReplaceFile } from 'sfportal_stores/transferStore'
import { useUserStore } from 'sfportal_stores/userStore'
import { useValuesStore } from 'sfportal_stores/valuesStore'
import { useIsMounted } from 'sfportal_utils/useIsMounted'
import { FileInputChangeListener, useFileInput } from '../../hooks/useFileInput'
import { getFileUriFromEMK } from '../../routes'
import { ApiTask, ApiTaskDataResourceAsset } from '../../services/api/apiSchemas'
import { apiGetDataresourceById } from '../../services/api/dataresourceApiService'
import { apiPutCustomProperty } from '../../services/api/officefilesApiService'
import { noop } from '../../utils/function'
import { getWebDAVUri, MsOfficeApp } from '../../utils/web-dav'
import { Button } from '../Forms/Button'
import { ButtonDropdown } from '../Forms/ButtonDropdown'
import { MdiIcon } from '../Generic/MdiIcon'
import { MenuItem } from '../Menu/MenuItem'
import { MenuTitle } from '../Menu/MenuTitle'
import { ListItemTask } from './ListItemTask'
import { useTaskActions } from './useTaskActions'
import { useAddSubscriptionToArea } from '../../hooks/useAddSubscriptionToArea'
import { useAssetPrivileges } from './useAssetPrivileges'
import { v4 as uuidv4 } from 'uuid'

interface Props {
  task: ApiTask
  showTaskName?: boolean
}

let lockRequestCreateTimeout: number | null = null
let lockRequestCreateIds: Array<ApiTask['id']> = []
let lockRequestDeleteTimeout: number | null = null
let lockRequestDeleteIds: Array<ApiTask['id']> = []

export const ListItemTaskAsset: FC<Props> = ({
  task,
  showTaskName = false
}) => {
  const { data } = useValuesStore()
  const {
    product,
    currentTask
  } = useProductDetailStore()
  const { currentUser } = useUserStore()
  const alert = useAlert()
  const [dataResourceIdentifier, setDataResourceIdentifier] =
    useState<string | undefined>(undefined)
  const { t } = useTranslation()
  const isMounted = useIsMounted()

  /** Asset-URI wird für iFrame verwendet, um das Öffnen in Word auszulösen. */
  const [assetUri, setAssetUri] = useState<string | null>(null)

  const dataResource = useMemo(
    () => task.dataResource as ApiTaskDataResourceAsset,
    [task.dataResource]
  )

  const { canUserDownloadPreview } = useAssetPrivileges(
    dataResource.assetPrivileges
  )

  const taskActions = useTaskActions({
    task,
    dataresourceLocked: dataResource.locked
  })

  const dataResourceId = useMemo(
    () => parseInt(dataResource.id.split('-')[0].replace('sf', '')),
    [dataResource.id]
  )

  const entityId = useMemo(
    () => parseInt(dataResource.id.split('-')[1]),
    [dataResource.id]
  )

  useEffect(() => {
    apiGetDataresourceById(dataResourceId).then(response => {
      if (!isMounted()) return
      setDataResourceIdentifier(response.body.identifier)
    }).catch(noop)
  }, [dataResourceId, isMounted])

  const lockCreateCallback = useCallback(() => {
    // Hier können sehr viele subs gleichzeitig aufschlagen wenn z.B. ein gesamtes Produkt gelockt wird.
    // Um hier performant zu bleiben, werden die locks erst aktualisiert wenn man 0.2 sekunden keine Lock subscription bekommen hat.
    lockRequestCreateIds.push(task.id)
    if (lockRequestCreateTimeout !== null) {
      window.clearTimeout(lockRequestCreateTimeout)
    }
    lockRequestCreateTimeout = window.setTimeout(() => {
      setLock(lockRequestCreateIds, true)
      lockRequestCreateIds = []
      lockRequestCreateTimeout = null
    }, 200)
  }, [task.id])

  // Subscriptions für das erstellen eines Locks auf das Asset.
  useAddSubscriptionToArea(dataResourceIdentifier, 'lockCreate', entityId.toString(), lockCreateCallback)

  const lockDeleteCallback = useCallback(() => {
    // Hier können sehr viele subs gleichzeitig aufschlagen wenn z.B. ein gesamtes Produkt entsperrt wird.
    // Um hier performant zu bleiben, werden die locks erst aktualisiert wenn man 0.2 sekunden keine Lock subscription bekommen hat.
    lockRequestDeleteIds.push(task.id)
    if (lockRequestDeleteTimeout !== null) {
      window.clearTimeout(lockRequestDeleteTimeout)
    }
    lockRequestDeleteTimeout = window.setTimeout(() => {
      setLock(lockRequestDeleteIds, false)
      lockRequestDeleteIds = []
      lockRequestDeleteTimeout = null
    }, 200)
  }, [task.id])

  // Subscriptions für das entfernen eines Locks auf das Asset.
  useAddSubscriptionToArea(dataResourceIdentifier, 'lockDelete', entityId.toString(), lockDeleteCallback)

  const handleFileInputChange = useCallback<FileInputChangeListener>(
    event => {
      if (product === null) return

      const inputElement = event.currentTarget as HTMLInputElement
      const file = inputElement.files?.[0] ?? null
      if (file === null) return

      const uploadOptions = {
        productId: product.id
      }

      const uploadId = uuidv4()

      startMetadataWf([
        {
          filename: file.name,
          uploadid: uploadId,
          userid: currentUser?.id,
          productid: product.id,
          entityMetakey: task.dataResource.id
        }
      ], true).catch(noop)

      uploadAndReplaceFile(file, uploadId, uploadOptions, task.id).catch(noop)
    },
    [currentUser, product, task.dataResource.id, task.id]
  )

  const { handleFileUploadClick } = useFileInput({
    listener: handleFileInputChange
  })

  const downloadLink = useMemo(
    () => getFileUriFromEMK(dataResource.id, 'download'),
    [dataResource.id]
  )

  const downloadPreviewLink = useMemo(
    () => {
      if (dataResource.previewPath === null) {
        return null
      }
      return getFileUriFromEMK(dataResource.previewPath)
    },
    [dataResource.previewPath]
  )

  const handleItemClick = async (): Promise<void> => {
    await updateTaskStateSingle(task.id)
    // Die Referenz auf den currentTasks ist hier noch nicht geupdated. Deswegen wird der Task explizit
    // nach dem Update aus der Datenbank aus dem Store geladen.
    let updatedTask = getStoreTaskById(task.id)
    if (updatedTask === null) {
      alert.show(t('tasks.gone'))
      return
    }
    setCurrentTask(task)
    await loadCorrectionPdf(updatedTask?.id ?? '')
    // Die Referenz auf den currentTasks ist hier noch nicht geupdated. Deswegen wird der Task explizit
    // nach dem Update aus der Datenbank aus dem Store geladen.
    updatedTask = getStoreTaskById(task.id)
    setCurrentFile(updatedTask === null ? null : updatedTask.dataResource).catch(noop)
  }

  const handleEditAssetClick = useCallback(
    async () => {
      if (data === null || product === null) return
      if (dataResource.webdavPath === null) return

      if (!task.assignee) {
        claimTasks(task.id).catch(noop)
      }

      let uri = ''
      // Abfrage auf Word- bzw. Excel-Datei für Ermittlung der Webdav-URL
      if (dataResource.contentType === 'file/xls') {
        uri = getWebDAVUri({
          app: MsOfficeApp.excel,
          fileUri:
            `${data.apiurl}/${data.webdavassetpath}/${dataResource.webdavPath}`
        })
      } else if (dataResource.contentType === 'file/doc') {
        uri = getWebDAVUri({
          app: MsOfficeApp.word,
          fileUri:
            `${data.apiurl}/${data.webdavassetpath}/${dataResource.webdavPath}`
        })
      }

      // Setzt die productId in die Word Datei, für den Baum des Word Plugins.
      await apiPutCustomProperty(
        dataResource.id,
        JSON.stringify({ productIdTemp: product.id })
      )

      ListItemTaskAssetUtils.handleEditAssetClick(
        dataResource.id,
        uri,
        setAssetUri,
        alert
      )
    },
    [data, product, dataResource.webdavPath, dataResource.contentType, dataResource.id, task.assignee, task.id, alert]
  )

  const displayDownloadPreviewButton = useCallback(
    (downloadPreviewLink: string | null): downloadPreviewLink is string => (
      canUserDownloadPreview &&
      downloadPreviewLink !== null &&
      (
        dataResource.contentType === 'file/doc' ||
        dataResource.contentType === '395'
      )
    ),
    [canUserDownloadPreview, dataResource.contentType]
  )

  const displayDownloadButton = useCallback(
    (): boolean => (
      downloadLink !== null &&
      dataResource.assetPrivileges.canUserDownload &&
      ListItemTaskAssetUtils.additionCanUserDownloadRequirement(dataResource)
    ),
    [dataResource, downloadLink]
  )

  const displayUploadButton = useCallback(
    (): boolean => (
      dataResource.assetPrivileges.canUserUpload &&
      ListItemTaskAssetUtils.additionCanUserUploadRequirement(dataResource)
    ),
    [dataResource]
  )

  return <>
    {taskActions.taskModalElement}

    <ListItemTaskAssetUtils.AdditionalRootRender task={task} />

    <HiddenIframe title={`list-item-task-asset ${task.id}`} src={assetUri} />

    <ListItemTask
      listItemType="asset"
      statusIcons={[
        ...ListItemTaskAssetUtils.getStatusIcons(dataResource, currentUser, task.assignee),
        ...dataResource.locked ? [<MdiIcon key={'lockicon'} path={mdiLock} />] : []
      ]}
      icon={
        dataResource.contentType?.startsWith('image') ?? false
          ? mdiImageOutline
          : mdiFileDocumentOutline
      }
      title={dataResource.name}
      titleSuffix={showTaskName ? task.name : undefined}
      highlight={currentTask?.id === task.id}
      current={currentTask?.id === task.id}
      dueDate={task.dueDate}
      modifiedBy={dataResource.modifiedBy ?? t('badges.status.unknown')}
      modifiedDate={dataResource.modifiedDate ?? t('badges.status.unknown')}
      onItemClick={handleItemClick}
      pubtargetIdentifier={task.pubtargetIdentifier}
      actions={<>
        {taskActions.taskActionElements}

        {data !== null &&
        dataResource.assetPrivileges.canUserEdit &&
        (!task.assignee || task.assignee === currentUser?.username.toString()) ? (
            <Button
              buttonStyle="inline"
              title={t('listItem.editFile')}
              icon={mdiPencil}
              onClick={handleEditAssetClick}
            />
          ) : null}

        {ListItemTaskAssetUtils.additionalDropDownRenderRequirement(dataResource, currentUser) ||
        displayDownloadPreviewButton(downloadPreviewLink) ||
        displayDownloadButton() ||
        displayUploadButton() ? (
            <ButtonDropdown
              buttonStyle="inline"
              buttonIcon={mdiDotsHorizontal}
              title={t('listItem.furtherActions')}
            >
              <MenuTitle label={dataResource.name} />

              {ListItemTaskAssetUtils.additionalDropDownRenderRequirement(
                dataResource,
                currentUser
              )
                ? (
                  <ListItemTaskAssetUtils.AdditionalDropDownRender
                    task={task}
                    dataresourceLocked={dataResource.locked}
                  />
                )
                : null}

              {displayDownloadPreviewButton(downloadPreviewLink) ? (
                <MenuItem
                  label={t('listItem.pdfPreviewDownload')}
                  icon={mdiFileDownloadOutline}
                  action={downloadPreviewLink}
                />
              ) : null}

              <MenuItem
                label={t('listItem.listItemTaskAsset.downloadFile')}
                icon={mdiDownload}
                enabled={displayDownloadButton() && (!task.assignee || task.assignee === currentUser?.username.toString())}
                action={downloadLink}
              />

              <MenuItem
                label={t('listItem.listItemTaskAsset.replaceFile')}
                icon={mdiUpload}
                enabled={displayUploadButton() && (!task.assignee || task.assignee === currentUser?.username.toString())}
                action={handleFileUploadClick}
              />
            </ButtonDropdown>
          ) : null}
      </>}
    />
  </>
}
