import React, {FC, useRef, useState} from 'react'
import {mdiCheckCircleOutline, mdiClose, mdiFileDocument, mdiFileImage, mdiFileMusic, mdiFileVideo, mdiPlayCircleOutline} from '@mdi/js/commonjs/mdi'
import Icon from '@mdi/react'
import {deleteWorkflow, FileFormat, updateWorkflow, UploadWorkflow} from '../../stores/workflowStore'
import {useTranslation} from 'react-i18next'
import {deleteWorkflowTasks, loadWorkflowByProcessinstanceId} from '../../services/api/workflowApiService'
import {noop} from '../../utils/function'
import '../styles/tasks.scss'
import {AssetDialogForm} from '../../views/AssetDialog/AssetDialogForm'
import {saveEntities, Value} from '../../services/api/entityApiService'
import {apiCompleteTasks} from '../../services/api/tasksApiService'
import {LoadingSpinner} from '../Animations/LoadingSpinner'
import {ProgressBar} from '../Generic/ProgressBar'
import {useSingleTransfer} from '../../stores/extensions/useSingleTransfer'
import {FormatDialog, ProductDetailFile} from '../../views/ProductDetail/ProductDetailView'
import {cloneDeep} from 'lodash'
import {useAlert} from 'react-alert'

interface Props {
    workflow: UploadWorkflow
}

type ActionTypes = 'close' | 'continue'

export const WorkflowItem: FC<Props> = (
    {
        workflow
    }
) => {
    const getIcon = (): string => {
        const extension = workflow.variables.filename.substring(workflow.variables.filename.lastIndexOf('.'))
        if (extension.includes('png') || extension.includes('jpg') || extension.includes('jpeg') || extension.includes('gif')) {
            return mdiFileImage
        }
        if (extension.includes('mp4') || extension.includes('avi')) {
            return mdiFileVideo
        }
        if (extension.includes('mp3') || extension.includes('wav')) {
            return mdiFileMusic
        }
        return mdiFileDocument
    }

    function getInitialFormatDialogValue(): FileFormat | undefined {
        const defaultValue = workflow.variables.fileFormatsSelectedDefaultValue
        const arrayOfDots = workflow.variables.filename.split('.')
        const valueForDefault = defaultValue !== undefined && arrayOfDots.length > 0 ? defaultValue[arrayOfDots.pop() ?? ''] : ''
        return workflow.variables.files?.[0].fileformats.find(single => single.identifier === valueForDefault)
    }

    const {t} = useTranslation()
    const alert = useAlert()
    const [isLoading, setIsLoading] = useState(false)
    const [showAssetModal, setShowAssetModal] = useState(false)
    const [showFormatModal, setShowFormatModal] = useState(false)
    const [formatDialogValue, setFormatDialogValue] = useState<FileFormat | undefined>(getInitialFormatDialogValue())
    const [icon] = useState<string>(getIcon)
    const workflowRef = useRef<UploadWorkflow>(workflow)
    workflowRef.current = workflow

    /**
     * Funktion, welche ausgeführt wird, wenn der User ein Icon des Workflows klickt
     * @param action die Aktion, welche durchgeführt werden soll
     */
    const interactWorkflow = (action: ActionTypes): void => {
        if (action === 'close') {
            deleteWorkflowTasks([workflow.processinstanceid]).catch(noop)
            deleteWorkflow(workflow.processinstanceid)
        }
        if (action === 'continue') {
            if (workflow.variables.dialogKey === 'metadata') {
                setShowAssetModal(true)
                return
            }
            if (workflow.variables.dialogKey === 'fileformats') {
                setShowFormatModal(true)
                return
            }
            alert.show(t('workflow.noDialog'))
        }
    }

    /**
     * Funktion zum speichern der Metadaten
     * @param values
     */
    const saveWorkflow = async (values: Value[]) => {
        setShowAssetModal(false)
        setIsLoading(true)
        const response = await saveEntities(values)
        const savedValues: Value[] = response.body
        const clonedWorkflow = cloneDeep(workflow)
        clonedWorkflow.variables.files[0] = {
            ...clonedWorkflow.variables.files[0],
            ...savedValues[0].entity,
            ['entityMetakey']: `${savedValues[0].dataresource}|${savedValues[0].entity.id}`
        }

        await apiCompleteTasks({
            taskId: clonedWorkflow.taskid,
            formFields: {
                files: clonedWorkflow.variables.files,
                cancelUpload: false,
                isExistsMetadata: true,
            }
        }).catch(() => alert.error(t('workflow.error')))
        fetchWorkflow()
    }

    /**
     * Funktion zum speichern der Format Daten
     */
    const saveFormat = async () => {
        setShowFormatModal(false)
        setIsLoading(true)
        const clonedWorkflow = cloneDeep(workflow)
        clonedWorkflow.variables.files[0].sffileformat = formatDialogValue ?? workflow.variables.files[0].fileformats[0]

        await apiCompleteTasks({
            taskId: clonedWorkflow.taskid,
            formFields: {
                cancelUpload: false,
                files: clonedWorkflow.variables.files
            }
        }).catch(e => alert.error(t('workflow.error')))
        fetchWorkflow()
    }

    /**
     * Funktion, welche das Fileformat setzt
     * @param format der Identifier des Formats
     */
    const onChangeFormat = (format: string) => {
        const formatToUse = workflow.variables.files[0].fileformats.find(single => single.identifier === format)
        if (formatToUse) {
            setFormatDialogValue(formatToUse)
        }
    }

    /**
     * Lädt den neuen Workflow, bis dieser seinen State nicht mehr ändert bzw. im nächsten Usertask hängt
     */
    const fetchWorkflow = (): void => {
        const fetchInterval = setInterval(async () => {
            const newWf = (await loadWorkflowByProcessinstanceId(workflowRef.current.processinstanceid)).body as UploadWorkflow[]
            if (newWf.length === 0) {
                setIsLoading(false)
                clearInterval(fetchInterval)
                return
            }
            const newWorkflow = newWf[0]
            //Workflow Updaten, da Fertig oder Fehlerhaft
            if (newWorkflow.state === 'COMPLETED' || newWorkflow.state === 'INTERNALLY_TERMINATED' || newWorkflow.incidentid !== null) {
                setIsLoading(false)
                clearInterval(fetchInterval)
                updateWorkflow(workflowRef.current.processinstanceid, newWorkflow)
                return
            }
            //Workflow Updaten, falls sich die Daten ändern
            if (newWorkflow.taskid !== workflowRef.current.taskid || newWorkflow.state !== workflowRef.current.state) {
                updateWorkflow(workflowRef.current.processinstanceid, newWorkflow)
                return
            }
            //Workflow Updaten, falls der Workflow im nächsten Usertask ist
            if (newWorkflow.taskid === workflowRef.current.taskid && (newWorkflow.taskid !== null && newWorkflow.state === 'ACTIVE')) {
                setIsLoading(false)
                clearInterval(fetchInterval)
            }
        }, 1000)
    }

    /**
     * Callback, wenn der Upload fertig ist.
     */
    const onUploadReady = (): void => {
        setIsLoading(true)
        fetchWorkflow()
    }

    return <div className={'workflowItem'}>
        {isLoading
            ? <div className={'spinner'}>
                <LoadingSpinner />
            </div>
            : null}
        {showAssetModal
            ? <AssetDialogForm onSave={saveWorkflow}
                               onCancel={() => setShowAssetModal(false)}
                               overrideValues={[{
                                   dataresource: workflow.variables.files[0].sffileformat.fksfdataresources,
                                   entity: {
                                       identifier: workflow.variables.filename,
                                       id: 0
                                   }
                               }]}
                               formTypes={['typeForNew', 'type']}
                               entityMetakeys={[`${workflow.variables.files[0].sffileformat.fksfdataresources}|0`]} />
            : null}
        {showFormatModal
            ? <FormatDialog isFormatDialogVisible={showFormatModal}
                            value={(formatDialogValue?.identifier) ?? ''}
                            title={workflow.variables.filename}
                            onChange={onChangeFormat}
                            onCancelDialog={() => setShowFormatModal(false)}
                            onAcceptDialog={saveFormat}
                            externalTask={{
                                taskId: workflow.taskid,
                                filename: workflow.variables.filename,
                                files: workflow.variables.files as unknown as ProductDetailFile[],
                                filepath: workflow.variables.filepath
                            }} />
            : null}
        <div style={{display: 'flex', alignItems: 'center', width: 'calc(100% - 140px)'}}>
            <div style={{width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Icon path={icon} style={{height: '55px'}} />
            </div>
            <WorkflowInfo workflow={workflow} onUploadReady={onUploadReady} />
        </div>
        <div className={'icon-container'}>
            {workflow.state === 'COMPLETED'
                ? <div onClick={() => interactWorkflow('close')} className={'icon-hover'} title={t('tooltip.delete')}>
                    <Icon size={1} path={mdiCheckCircleOutline} />
                </div>
                : null}
            {workflow.state === 'ACTIVE' && workflow.taskid !== null
                ? <div onClick={() => interactWorkflow('continue')} className={'icon-hover'} title={t('tooltip.interact')}>
                    <Icon size={1} path={mdiPlayCircleOutline} />
                </div>
                : null}
            {workflow.state === 'INTERNALLY_TERMINATED' || workflow.incidentid !== null
                ? <div onClick={() => interactWorkflow('close')} className={'icon-hover'} title={t('tooltip.delete')}>
                    <Icon size={1} path={mdiClose} />
                </div>
                : null}
        </div>
    </div>
}

interface WorkflowInfoProps {
    workflow: UploadWorkflow
    onUploadReady: () => void
}

const WorkflowInfo: FC<WorkflowInfoProps> = (
    {
        workflow,
        onUploadReady
    }
) => {
    const {t} = useTranslation()
    const progress = useSingleTransfer(workflow.variables.uploadid)?.progress
    const alreadyLoading = useRef(false)
    if (progress === 100 && !alreadyLoading.current) {
        alreadyLoading.current = true
        onUploadReady()
    }

    return <div className={'workflow-info'}>
        <div className={'data-container'}>
            <span className={'ellipse-identifier'}><strong>{workflow.variables.filename}</strong></span>
            <div style={{display: 'flex', gap: '10px'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span className="list-item-task__meta-value">{t('workflow.started')}</span>
                    <span className="list-item-task__meta-value">{t('workflow.end')}</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span className="list-item-task__meta-value">{new Date(workflow.starttime).toLocaleString()}</span>
                    <span className="list-item-task__meta-value">{workflow.endtime ? new Date(workflow.endtime).toLocaleString() : t('workflow.running')}</span>
                </div>
            </div>

        </div>
        {workflow.state === 'INTERNALLY_TERMINATED' || workflow.incidentid !== null
            ? <div className="item-view__progress-col">
                <span style={{color: 'red'}}>{t('workflow.upload.error')}</span>
            </div>
            : null}
        {workflow.state !== 'INTERNALLY_TERMINATED' && workflow.incidentid === null
            ? <div className="item-view__progress-col">
                {progress && progress !== 100
                    ? <ProgressBar
                        progress={progress ?? 100}
                        showPercent={true}
                        label={
                            (progress ?? 100) < 100
                                ? (t('workflow.upload.running'))
                                : (t('workflow.upload.progressDoneDefault'))
                        }
                    />
                    : <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span>{workflow.state === 'COMPLETED' ? t('workflow.progressDoneComplete') : t('workflow.upload.progressDone')}</span>
                        {workflow.taskid !== null
                            ? <span>{t('workflow.interaction')}</span>
                            : null}
                    </div>}
            </div>
            : null}
    </div>
}
