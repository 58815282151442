import { ProgressEvent, Response } from 'superagent'
import { ApiJwtAuthenticationDetails, ApiProduct, ApiSharedFolder, ApiTask, EMK } from './apiSchemas'
import { FormBody } from './generic/types'
import { sendForm, sendRequest } from './superagent'

interface UploadFileParams {
  file: File
  productId: ApiProduct['id']
  folderId?: ApiSharedFolder['id']
  entityMetaKey?: ApiTask['dataResource']['id']
  progressCallback?: (event: ProgressEvent) => void
  userId?: ApiJwtAuthenticationDetails['id']
  variables?: string
}

export interface UploadMetadataWfFile {
  filename: string
  uploadid: string
  folderid?: EMK
  userid?: number
  productid: number
  entityMetakey?: EMK
}

export async function sendFile (params: UploadFileParams): Promise<Response> {
  const { file, productId, folderId, entityMetaKey, progressCallback, userId, variables } = params
  const body: FormBody = { file, productId: productId.toString() }

  if (userId) {
    body.userId = userId.toString()
  }
  if (variables !== undefined) {
    body.variables = encodeURIComponent(variables)
  }
  if (entityMetaKey !== undefined) {
    body.entityMetaKey = entityMetaKey
  } else if (folderId !== undefined) {
    body.folderId = folderId
  } else {
    throw new Error('Either "fileId" or "folderId" must be passed to "sendFile()"')
  }
  return await sendForm('files', { body, progressCallback })
}

export async function fetchShares (
  productId: ApiProduct['id'],
  context: () => unknown,
  orderBy?: string
): Promise<Response | null> {
  const startingContext = context()
  const query = { productId: productId.toString(), orderBy }
  const result = await sendRequest('get', 'files/shared', { query })
  return startingContext === context() ? result : null
}

/**
 * Lädt die Info, ob freigegebene Ordner für ein Produkt vorhanden sind.
 *
 * @param productId Die Id des Produkts.
 */
export async function checkSharesAvailable (
  productId: ApiProduct['id']
): Promise<Response | null> {
  const query = { productId: productId.toString() }
  return await sendRequest('get', 'files/shared/check', { query })
}

export async function fetchFileMetadata (
  productId: ApiTask['dataResource']['id'],
  context: () => unknown
): Promise<Response | null> {
  const startingContext = context()
  const query = { entityMetaKey: productId }
  const result = await sendRequest('get', 'metadata', { query })
  return startingContext === context() ? result : null
}

/**
 * Lädt die Fileinfo für ein Asset.
 *
 * @param entityMetaKey Der EMK des Assets.
 */
export async function fetchFileInfo (
  entityMetaKey: EMK
): Promise<Response | null> {
  const query = { entityMetaKey: entityMetaKey }
  return await sendRequest('get', 'files/info', { query })
}

/**
 * Prüft ob auf dem übergebenen entityMetaKey ein Lock existiert.
 *
 * @param entityMetaKey Der EntityMetaKey.
 */
export async function apiHasLock (
  entityMetaKey: EMK
): Promise<Response | null> {
  return await sendRequest('get', 'locks/' + entityMetaKey + '/locked')
}

/**
 * Lockt ein Asset.
 *
 * @param entityMetaKey Der EntityMetakey des Assets.
 */
export async function apiLockAsset (
  entityMetaKey: EMK
): Promise<Response | null> {
  return await sendRequest('put', 'locks/' + entityMetaKey + '/locks')
}

/**
 * Löscht den Lock auf ein Asset.
 *
 * @param entityMetaKey Der EntityMetakey des Assets.
 */
export async function apiDeleteAssetLock (
  entityMetaKey: EMK
): Promise<Response | null> {
  return await sendRequest('put', 'locks/' + entityMetaKey + '/locks/delete')
}

export async function apiImageGet (
  id: number
): Promise<Response | null> {
  return await sendRequest('get', `image/${id}/info`)
}

export async function apiStartUploadAfterTransferWF (uploadingFiles: UploadMetadataWfFile[], variables: string): Promise<Response | null> {
  const sendOptions = {
    body: {
      uploadingFileMetadata: uploadingFiles,
      wfVariables: variables
    }
  }
  return await sendRequest('post', 'files/simpleupload/start', sendOptions)
}

export async function apiTransferFile (file: File, uploadid: string, progressCallback?: UploadFileParams['progressCallback']): Promise<Response | null> {
  const body: FormBody = { file, uploadid }

  return await sendForm('files/transfer', { body, progressCallback })
}
