import { mdiDotsHorizontal, mdiFileDocumentOutline, mdiFileDownloadOutline, mdiPencil } from '@mdi/js'
import React, { memo, useCallback, useMemo } from 'react'
import { useHttpRequest } from '../../hooks/useHttpRequest'
import { getFileUriFromEMK } from '../../routes'
import { ApiSmashdocsLink, ApiTask, ApiTaskDataResourceSmashdocs } from '../../services/api/apiSchemas'
import { getSmashdocsLink } from '../../services/api/smashdocsApiService'
import { claimTasks, setCurrentFile, useProductDetailStore } from '../../stores/productDetailStore'
import { useValuesStore } from '../../stores/valuesStore'
import { noop } from '../../utils/function'
import { Button } from '../Forms/Button'
import { ButtonDropdown } from '../Forms/ButtonDropdown'
import { MenuItem } from '../Menu/MenuItem'
import { MenuTitle } from '../Menu/MenuTitle'
import { ListItemTask } from './ListItemTask'
import { useTaskActions } from './useTaskActions'
import { useTranslation } from 'react-i18next'
import { useAssetPrivileges } from './useAssetPrivileges'

interface Props {
  task: ApiTask
  showTaskName?: boolean
  highlight?: boolean
}

export const ListItemTaskSmashdocs = memo(function ListItemTaskSmashdocs ({
  task, highlight,
  showTaskName = false
}: Props) {
  const dataResource = task.dataResource as ApiTaskDataResourceSmashdocs
  const { canUserDownloadPreview } = useAssetPrivileges(
    dataResource.assetPrivileges
  )

  const { data } = useValuesStore()
  const { currentFile } = useProductDetailStore()
  const [, , loadSmashdocsData] = useHttpRequest<ApiSmashdocsLink>(
    async () => await getSmashdocsLink({ id: dataResource.uuid })
  )

  const { t } = useTranslation()

  const taskActions = useTaskActions({ task })

  const validPreviewPath = useMemo(
    () => (
      dataResource.previewPath !== undefined &&
            dataResource.previewPath !== ''
    ),
    [dataResource.previewPath]
  )

  const downloadPreviewLink = useMemo(
    () => {
      if (dataResource.previewPath === null) {
        return null
      }
      return getFileUriFromEMK(dataResource.previewPath)
    },
    [dataResource.previewPath]
  )

  const handleEditAssetClick = useCallback(
    () => {
      (async () => {
        if (!task.assignee) {
          claimTasks(task.id).catch(noop)
        }

        const result = await loadSmashdocsData()
        if (result === null) {
          return
        }
        globalThis.open(result.url)
      })().catch(noop)
    },
    [loadSmashdocsData, task.assignee, task.id]
  )

  const handleItemClick = useCallback(
    () => {
      if (!validPreviewPath) {
        return
      }
      setCurrentFile(dataResource).catch(noop)
    },
    [dataResource, validPreviewPath]
  )

  return <>
    {taskActions.taskModalElement}

    <ListItemTask
      listItemType="asset"
      icon={mdiFileDocumentOutline}
      title={dataResource.name}
      titleSuffix={showTaskName ? task.name : undefined}
      highlight={highlight}
      current={currentFile?.id === dataResource.uuid}
      dueDate={task.dueDate}
      onItemClick={
        canUserDownloadPreview && validPreviewPath ? handleItemClick : undefined
      }
      actions={<>
        {taskActions.taskActionElements}

        {data !== null && dataResource.assetPrivileges.canUserEdit ? (
          <Button
            buttonStyle="inline"
            title={t('listItem.editFile')}
            icon={mdiPencil}
            onClick={handleEditAssetClick}
          />
        ) : null}

        {canUserDownloadPreview ? (
          <ButtonDropdown
            buttonStyle="inline"
            buttonIcon={mdiDotsHorizontal}
            title={t('listItem.furtherActions')}
          >
            <MenuTitle label={dataResource.name} />

            {downloadPreviewLink !== null && canUserDownloadPreview ? (
              <MenuItem
                label={t('listItem.pdfPreviewDownload')}
                icon={mdiFileDownloadOutline}
                action={downloadPreviewLink}
              />
            ) : null}

          </ButtonDropdown>
        ) : null}
      </>}
    />
  </>
})
